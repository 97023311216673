import { useEffect } from "react";
import Seo from "../components/seo";
import Navigation from "../components/navigation";
import "../assets/scss/page-not-found.scss";
import PageWrapper from "../templates/page-wrapper";
import { addCursor } from "../util";
// markup
const NotFoundPage = () => {
  useEffect(() => {
    addCursor();
  }, []);

  return (
    <PageWrapper>
      <Navigation />
      <Seo title="Not found" />
      <main className="not_found layout">
        <div className="hero__desc__contact hidden">
          <div>
            <p className="hero__desc__contact_title">OLUCHI NWENYI</p>
          </div>
          <div className="hero__desc__contact_email">
            <div>
              <span>EMAIL: </span>
              <a href="mailto:oluchinwenyi@gmail.com">OLUCHINWENYI@GMAIL.com</a>
            </div>
          </div>
        </div>
        <h1 className="not_found__title">
          Oops! Something’s <br /> wrong.
        </h1>
        <p className="not_found__desc">
          Sorryyyyyy! The page you’re looking for does not exist. Consider
          checking the URL for typos or continue your search by going back.
        </p>
      </main>
    </PageWrapper>
  );
};

export default NotFoundPage;
